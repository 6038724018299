@font-face {
  font-family: "Myriad";
  src: url("../fonts/myraid/MyriadPro.ttf") format("ttf");
}
@font-face {
  font-family: "Perpetua";
  src: url("../fonts/perpetua/PerpetuaTitlingMTLight2.ttf") format("ttf");
}
body {
  margin: 0px !important;
  background-color: #cccdcd;
}

.font-perpetua {
  font-family: "Perpetua" !important;
}

.font-myraid {
  font-family: "Myriad" !important;
}

.font-segeo {
  font-family: "segoe-ui-emoji-normal", sans-serif !important;
}

.font-roboto {
  font-family: "Roboto" !important;
}

.image-gallery-slide .image-gallery-image {
  -o-object-fit: fill !important;
     object-fit: fill !important;
}

.category-item {
  margin: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.category-item img {
  width: 200px;
  height: 200px;
  border: 1px solid #000;
  -o-object-fit: cover;
     object-fit: cover;
  overflow: hidden;
}

.category-item .title {
  display: -webkit-box;
  max-width: 200px;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  padding-left: 2px;
  padding-right: 2px;
  margin-top: 10px;
}

.categories-wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}

.pointer {
  cursor: pointer;
}

.list-thumbnail {
  -o-object-fit: cover;
     object-fit: cover;
  width: 60px;
  height: 60px;
  border-radius: 12px;
  background-color: #eee;
  margin-right: 20px;
}

.thumbnail-preview {
  -o-object-fit: cover;
     object-fit: cover;
  width: 100px;
  height: 100px;
  border-radius: 12px;
  background-color: #eee;
  margin-right: 20px;
}

.cover-preview {
  -o-object-fit: contain;
     object-fit: contain;
  width: 500px;
  height: 350px;
  border: none;
  background-color: #aeaeae;
}

.cover-full {
  border: none;
  height: 500px !important;
  width: 100% !important;
  -o-object-fit: contain !important;
     object-fit: contain !important;
  overflow: hidden !important;
  background-color: #aeaeae !important;
}

.flex-row {
  display: flex;
  flex-direction: row;
  gap: 5;
  align-items: center;
}

.flex-end {
  justify-content: flex-end;
}

.video-box {
  margin-bottom: 25px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.video-box .title {
  display: -webkit-box;
  max-width: 700px;
  -webkit-box-orient: vertical;
  overflow: hidden;
  padding-left: 2px;
  padding-right: 2px;
  margin-top: 10px;
  text-align: justify;
}

.video-box-thumbnail {
  height: 250px;
  width: 200px;
  margin-left: 15px;
  margin-right: 15px;
  margin-bottom: 15px;
}

.video-box-thumbnail .title {
  display: -webkit-box;
  max-width: 200px;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  padding-left: 2px;
  padding-right: 2px;
  margin-top: 5px;
}

.d-none {
  display: none;
}

.image-gallery-thumbnail .image-gallery-thumbnail-image {
  height: 60px;
  -o-object-fit: cover;
     object-fit: cover;
  border-radius: 12px;
}

.plan-item {
  padding: 20px;
  border-radius: 12px;
  background-color: #eee;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 250px;
}

.plan-item:hover {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.MuiButton-root {
  border-radius: 25px !important;
}

.parent {
  display: grid;
  grid-template-rows: repeat(4, auto);
  grid-gap: 10px;
  grid-auto-flow: column;
}

.home .image-gallery-image {
  height: 500px !important;
  width: 100% !important;
  -o-object-fit: contain !important;
     object-fit: contain !important;
  overflow: hidden !important;
  background-color: #aeaeae !important;
}

.category-details .image-gallery-image {
  height: 100% !important;
  width: 100% !important;
  -o-object-fit: contain !important;
     object-fit: contain !important;
  overflow: hidden !important;
  background-color: #aeaeae !important;
}/*# sourceMappingURL=style.css.map */